// Charge la base commune
require('./common.js')

// Styles librairies

// Styles custom

// Scripts librairies
import 'script-loader!waypoints/lib/jquery.waypoints.min.js'

// Pages

// OwlCarousel
async function getOwlCarousel() {
  const { default: _ } = await import('lodash')
  import('./js/components/owl-carousel.js')
}

// actions principales communes à toutes les pages
$(document).ready(function() {

  // gestion des carousel
  var $f = $('div.owl-carousel')
  if ( $f.length ) {
    var waypoints = $f.waypoint(function(direction) {
      getOwlCarousel()
      this.destroy()
    }, {
      offset: '100%'
    })
  }

})
