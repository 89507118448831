/* fichier JS de base commun quelques soient les versions du projet web */

// charge bootstrap nécessite jquery et popper
import 'bootstrap/js/dist/collapse'
import 'bootstrap/js/dist/dropdown'
import 'bootstrap/js/dist/util'
//import 'bootstrap' // directement déclaré dans le fichier de config webpack base
import 'lazysizes' // lazy loading https://github.com/aFarkas/lazysizes
lazySizes.cfg.expand = 80
lazySizes.cfg.expFactor = 1
$('body').removeClass('no-js')

// pas de ./ car on résout dans theme donc déjà déclaré dans resolve.modules dans webpack.base.js
// l'extension n'est pas obligatoire car connue dans resolve.extensions, ne fonctionnera pas si il y a des fichiers de différentes extensions portant le même nom (ex: bootstrap.css et bootstrap.js dans le même dossier)
//import 'bootstrap/scss/bootstrap'
import 'bootstrap/scss/bootstrap-custom'

// Styles template

// Styles librairies

// Styles custom
import './scss/custom'

// Plugins template
//import 'script-loader!js/plugins.js'

// Scripts librairies
import 'script-loader!js/custom_script'
import 'script-loader!./js/libs/utils'
//import '!./js/components/cookie.js'

// lazysizes : add simple support for background images :
document.addEventListener('lazybeforeunveil', function(e) {
  var bg = e.target.getAttribute('data-bg')
  if ( bg ) e.target.style.backgroundImage = 'url(' + bg + ')'
})

// Utils
async function getScrollToTop() {
  const { default: _ } = await import('lodash')
  import('./js/libs/scrollToTop/scrollToTop.js').then(module => {
    $.scrollUp({
      easingType: 'linear',
      topSpeed: 300,
      scrollSpeed: 400,
      animation: 'fade',
      scrollText: '<i class="icon-angle-up"></i>'
    })
  })
}

/*
// sur le resize de l'écran
function handleResize() {
  var width = $(window).width()
  if ( width < 1200 ) {
    // charge le css pour le menu mobile

  } else {
    // charge le css pour le menu desktop

  }
}
*/

// Actions principales communes à toutes les pages
$(document).ready(function() {

//  $(window).resize(function() {
//    console.log('resized')
//    handleResize()
//  })

  // utils
  $(window).scroll(function() {
    if ( $(window).scrollTop() >= 160 ) {
      if ( !$('#scrollUp').length ) getScrollToTop()
    }
  })

  // boutons de partage
  $('button[data-toggle="share"]').on('click', function() {
    window.open($(this).attr('data-href'))
  })

  // formulaires de recherche
  $('form[name="search_form"]').on('submit', function() {
    if ( $(this).find('input[name="field"]').val().replace(/\s/g, '').length ) { // le champ de recherche doit être différent que des espaces / tabs / breaklines etc.
      $(this).attr('action', $(this).attr('action') + '?s=' + ($(this).find('input[name="field"]').val().replace(/ /g, '-') + '').toLowerCase())
    }
  })

  extendClick()

})
